import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, FirstP, Emoji } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>The linear clock has been on my desk since it has been finished. However, there were a few issues with the design that I wanted to address. First of all, the build quality 
            could have been better, and the build process was quite involved. But more importantly, the display was not very readable in some conditions. In this update I will address these issues and present the second version of the
            linear clock design.
        </FirstP>
         
        <p>No clickbait practices here, so lets start by showing the finished product. I am quite happy with the way it looks and the improvements to the display. In the next sections 
            I will discuss the design and some of the issues that it solves.
        </p>

        <Img fadeIn={false} style={{ marginBottom: '1em' }} fluid={data.img3.childImageSharp.fluid} alt="Linear clock" />
        
        <Img fadeIn={false} fluid={data.img4.childImageSharp.fluid} alt="Linear clock" />
        <Caption>Second version of the linear clock</Caption>

        <h3>LED orientation</h3>

        <p>In the first version of the clock I used the principle of edge-lit acrylic. I discovered this shortly before starting the project and wanted to build something with it.
            It turned out quite well, but it also created some issues. I had to make small pieces of acrylic that contained the indivdual numbers, and assemble these into a single frame.
            Also, since the LED's had to be on the top and the bottom, it was not easy to make a custom circuit board for the clock.
        </p>

        <p>The main change in this design is therefore that I ditched the edge-lit approach. Instead the LED's are right behind the clock face and shine in the same direction. 
            With this the design could be simplified a lot. However, this also creates a new challenges, since the light needs to be diffused. But more on that later.
        </p>
        
        <h3>PCB design</h3>

        <p>
            With the LED's behind the display it was easier to make a simple PCB, containg the ESP8266 and the individual lights as shown below. For the design I used WS2812B LED's from
            Worldsemi which are quite popular among makers. A quick tip: I found out that an updated version (version 5) of this LED is available, which is called the WS2812B-V5. This fifth version
            has two main advantages:
        </p>

        <ol>
            <li>There is no longer a need to have a capacitor for each LED. I know there are other LED's such as the WS2813 that address this, but with V5 this is also the case for the
                more common WS2812</li>
            <li>The WS2812-V5 explicitly accepts 3.3V logic signals. In the past 3.3V logic would probably work as well, but to be safe you had to use a level shifter. Not anymore.</li>
        </ol>

        <Img fadeIn={false} style={{ marginBottom: '1em' }} fluid={data.img6.childImageSharp.fluid} alt="Linear clock" />

        <Img fadeIn={false} fluid={data.img7.childImageSharp.fluid} alt="Linear clock" />
        <Caption>Clock circuit board.</Caption>

        <h3>Design components</h3>

        <p>The first version of the clock had 106 individual component, which has now been reduced to 6 <Emoji e="😎" />.</p>

        <Img fadeIn={false} fluid={data.img5.childImageSharp.fluid} alt="Linear clock" />
        <Caption>Parts of the clock.</Caption>

        <p>From top to bottom these are:</p>

        <ol>
            <li>The housing. To be fair, I probably need to update this, since the USB port is sticking out in the back which is not the nicest solution.</li>
            <li>The circuit board.</li>
            <li>The light guides; these block the light leakage between different segments of the clock.</li>
            <li>A diffuser, in this case a piece of tracing paper cut to size.</li>
            <li>A laser cut piece of black cardboard containing the cut-outs for the clock.</li>
            <li>A grey transparent acrylic front face.</li>
        </ol>

        <h3>Old vs. New</h3>

        <p>As you can see in the pictures below, the other advantage of the new design is readability.
        In the previous design with the LED strips the numbers had to be slightly recessed in the housing.
        Because of this the viewing angle of the clock was small. In the top picture you can see that 12, 13, 14... reads as 2, 3, 4...
        There is now also less light leakage around the numbers, and they are significantly larger in size. If you look closely you see
        that there are small gaps in the new numbers. This is required to keep everything in place, since this face is now laser cut.
        I custom designed this for the best readability.
        </p>
                
        <Img fadeIn={false} style={{ marginBottom: '1em' }} fluid={data.img1.childImageSharp.fluid} alt="Linear clock" />
        
        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Linear clock" />
        <Caption>Improved readability.</Caption>

        <p>Finally, I also like the handling of colors a lot better with the new numbers. 
            It could be really nice to use the LED bar to show a piece of information, while a cursor
            in the top bar indicates the current time.
            In my previous visualizations I did not use this effect
            simply because I thought it was ugly since the entire square around the number is lighting up. In the new
            design this is a lot cleaner, so it can be used without any problems.
        </p>

    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "DSCF5625.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "DSCF5626.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img3: file(relativePath: { eq: "DSCF5636.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img4: file(relativePath: { eq: "DSCF5639.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img5: file(relativePath: { eq: "DSCF5643.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img6: file(relativePath: { eq: "DSCF5647.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img7: file(relativePath: { eq: "DSCF5649.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`